import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { useGlobalState } from '../../../state/globalStateContext';
import { RawPortableText } from '../../../types/types';
import { useStoreReferrerOnLoad, useStoreUTMParamsOnLoad } from '../../../utils/hooks';
import { uniqBy } from '../../../utils/nodash';
import { useStoreScheduleOnceCalendarOnLoad } from '../../../utils/projectUtils';
import { getPortableTextAsString } from '../../../utils/sanity';
import {
  clsx,
  generateUniqueId,
  getLocalStorageMemoizedValue,
  slugify,
  withDataLayer,
  wrapSquareBracketedWithEm,
} from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Form, { FormField, FormFieldWithId } from '../Form';
import ModuleLayout from '../ModuleLayout';
import * as styles from './FormModule.module.scss';

export type FormModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  formType: 'manualInputs' | 'scheduleOnce';
  title?: string;
  subtitle?: string;
  text?: string;
  className?: string;
} & (
  | {
      formType: 'manualInputs';
      form: {
        fields: Array<FormField>;
        submitButtonText?: string;
        thankYouScreen: {
          title: string;
          subtitle?: string;
          _rawText?: RawPortableText;
          scheduleACallButton?: ButtonLinkType;
        };
      };
    }
  | {
      formType: 'scheduleOnce';
      form?: never;
    }
);

export function getModuleBgColor(props: FormModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FormModule(props: FormModuleProps & CommonModuleProps): React.ReactElement {
  const {
    backgroundColor,
    formType,
    title,
    subtitle,
    text,
    form,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const formFieldsWithIds: Array<FormFieldWithId> =
    formType === 'manualInputs'
      ? form.fields.map(formField => ({
          id: slugify(
            formField.fieldType === 'singleCheckbox'
              ? formField.rawId || getPortableTextAsString(formField._rawText as RawPortableText)
              : formField.title,
          ),
          ...formField,
        }))
      : [];

  if (formFieldsWithIds!.length !== uniqBy(formFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(formFieldsWithIds!.map(formField => formField.id)),
    );
  }

  const { scheduleOnceCalendar } = useGlobalState();
  const socalendarScheduleEventTriggered = useRef(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();
  useStoreScheduleOnceCalendarOnLoad();

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (formType === 'scheduleOnce' && scheduleOnceCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !socalendarScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: scheduleOnceCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            socalendarScheduleEventTriggered.current = true;
          }
        },
        false,
      );
    }
  }, [scheduleOnceCalendar]);

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.formContainer}
    >
      {formType === 'scheduleOnce' ? (
        <>
          <Helmet>
            {hasLoaded && (
              // ScheduleOnce embed
              <script type="text/javascript" src="https://cdn.oncehub.com/mergedjs/so.js"></script>
            )}
          </Helmet>
          <div className={styles.textContainer}>
            <div className={styles.titleContainer}>
              {title && <h2 className={styles.title}>{wrapSquareBracketedWithEm(title)}</h2>}
              {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
              <div className={styles.titleDivider}></div>
            </div>
            {text && (
              <p className={clsx(styles.text, text.length > 300 && styles.smallerFont)}>{text}</p>
            )}
          </div>
          {scheduleOnceCalendar && (
            <div
              id={'SOIDIV_' + scheduleOnceCalendar}
              data-so-page={scheduleOnceCalendar}
              data-height="550"
              data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
              data-psz="10"
              className={styles.calendar}
            ></div>
          )}
        </>
      ) : (
        <Form
          formType="newsletter"
          fields={formFieldsWithIds}
          title={title}
          subtitle={subtitle}
          text={text}
          thankYouScreen={form.thankYouScreen}
          className={styles.form}
        />
      )}
    </ModuleLayout>
  );
}

export default FormModule;
